import React,{useEffect, useState} from 'react'
import { CardHeaderWithActions, MwButton, MwInputFile, MwInputText, MwSelector, MwSpinnerButton, MwTextArea } from '../../../components/ui';
import { useFetch, usePost,useUploadFile } from '../../../hooks';
import OrderFormPrinting from './OrderFormPrinting';
import { useNavigate, useParams } from 'react-router-dom';

const OrderForm = ({mode,setMode,currentTenantId,currentCompanyId,currentBranchId,currentLangId,userId}) => {
    const navigate = useNavigate();
    const {tenant} = useParams();

    const [errors,setErrors] = useState({});
    const allowImgTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];
    
    // Customer
    const [customers,setCustomers] = useState([]); 
    const [customerSelected,setCustomerSelected] = useState([]); 
    const [showCreateCustomerModal,setShowCreateCustomerModal] = useState(false);
    const [isAddedCustomer,setIsAddedcustomer] = useState(false); 
    // Salesman
    const [salesmans,setSalesmans] = useState([]); 
    const [salesmanSelected,setSalesmanSelected] = useState([]); 
    const [showCreateSalesmanModal,setShowCreateSalesmanModal] = useState(false);
    const [isAddedSalesman,setIsAddedSalesman] = useState(false); 
    
    const [status,setStatus] = useState(); 
    const [stages,setStages] = useState(); 
    // FormState
    const [formState,setFormState] = useState({
        orderNo:'',
        orderName:'',
        qty:'1',
        notes:'',
        attachments:[],
        deliveryDate:'',
        invoiceNo:'',
        quoteNo:'',
        status: null,
        stage: null,

    });

    // Printing State
    const [printingState,setPrintingState] = useState({
        desginerName: null,
        printingType: null,
        solofanType: null,
        paperWeight: '',
        paperType: '',
        paperSizeType: null,
        uvi:'',
        touch: '',
        finalSize:'',
        orderFinish:null,
        crefType: null,
        cutType: null,
        sharnkType: null,
    });
    const {data:dataGenOrderNo,loading:loadingGenOrderNo} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/orders/gen-order-no/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:dataCustomers,loading:loadingCustomers} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/customers/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:dataSalesman,loading:loadingSalesman} = useFetch(`${process.env.REACT_APP_Auth_API_BASE_URL}/salesmans/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:dataStatus,loading:loadingStatus} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/status/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:dataStages,loading:loadingStages} = useFetch(`${process.env.REACT_APP_API_BASE_URL}/stages/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    // upload attachments
    const {location:selectedAttachments,data:dataUpload ,loading:loadingUploadAttachments,handleUpload:handleAttachmentsUpload} = useUploadFile(allowImgTypes,`production-orders-${currentTenantId}`,'image')
    const {data:postResult,loading:postLoading,postData} = usePost();

    const resetForm = () => {

    }
    const validateForm = () => {
        setErrors({});
        let _errors = {};
        if(!formState?.orderName){
            _errors  = {..._errors,orderName:'يجب ادخال وصف مختصر '};
            setErrors({..._errors})
        }
        if(!formState?.notes){
            _errors  = {..._errors,notes:'يجب ادخال التفاصيل '};
            setErrors({..._errors})
        }

        if(_errors && Object.keys(_errors).length > 0 ){
            console.error(_errors);
            return true;
        }
        return false;
    }

    const onSubmitHandler = () => {
        if(validateForm()){
            return;
        }
        const _data = {
            order_no : formState.orderNo,
            order_name : formState.orderName,
            customer_id : customerSelected?.value  || null,
            customer_name : customerSelected?.label  || null,
            salesman_id : salesmanSelected?.value  || null,
            salesman_name : salesmanSelected?.label  || null,
            responsible_id : null,
            responsible_name : null,
            qty : formState.qty,
            attachments : [... new Set(formState.attachments)] ,
            delivery_date : formState.deliveryDate,
            notes : formState.notes,
            invoice_no : formState.invoiceNo,
            quote_no : formState.quoteNo,
            class_id : 1,
            status_id : formState.status?.value || null ,
            stage_id : formState.stage?.value || null,
            type_id : 1,
            created_by : userId,
            tenant_id : currentTenantId ,
            company_id : currentCompanyId,
            branch_id : currentBranchId,
            printing_class : {
                desginer_id : printingState.desginerName?.value,
                desginer_name : printingState.desginerName?.label  || null,
                printing_type : printingState.printingType?.value || null,
                paper_weight : printingState.paperWeight,
                paper_type : printingState.paperType,
                paper_size_type : printingState.paperSizeType?.value || null,
                solofan_type : printingState.solofanType?.value || null,
                uvi : printingState.uvi  || null,
                touch : printingState.touch  || null,
                final_size : printingState.finalSize  || null,
                order_finish : printingState.orderFinish  || null,
                cref_type : printingState.crefType  || null,
                cut_type : printingState.cutType  || null,
                sharnk_type : printingState.sharnkType  || null
            }
        }
        console.log("_data",_data)
        postData(`${process.env.REACT_APP_API_BASE_URL}/orders`,_data);
    }
    useEffect(() => {
        if(dataGenOrderNo){
            setFormState((state) =>  ({...state,orderNo:dataGenOrderNo.data}) )
        }
    },[dataGenOrderNo]);

    useEffect(() => {
        if(selectedAttachments){
            if(dataUpload?.originalname  ){
                formState?.attachments?.push({order_details_id:null,name:dataUpload?.originalname ,location:selectedAttachments})
                setFormState((state) =>  ({...state}) )
                console.log("formState?.attachments",formState?.attachments)
            }
        }
    },[selectedAttachments]);

    useEffect(() => {
        if(dataCustomers){
            console.log('dataCustomers => ',dataCustomers)
            setCustomers(dataCustomers.data)
        }
    },[dataCustomers]);

    useEffect(() => {
        if(dataSalesman){
            console.log('dataSalesman => ',dataSalesman)
            setSalesmans(dataSalesman.data)
        }
    },[dataSalesman]);

    useEffect(() => {
        if(dataStatus){
            console.log('dataStatus => ',dataStatus)
            const _statusHandle = dataStatus?.data.map(stage => ({
                label: stage.name_ar,
                value: stage.id
            }));
            setStatus(_statusHandle)
            setFormState((state)=> ({...state,status:_statusHandle[0]}))
        }
    },[dataStatus]);


    useEffect(() => {
        if(dataStages){
            console.log('dataStages => ',dataStages)
            const _stageHandle = dataStages?.data.map(stage => ({
                label: stage.name_ar,
                value: stage.id
            }));
            setStages(_stageHandle)
            setFormState((state)=> ({...state,stage:_stageHandle[0]}))
        }
    },[dataStages]);

    useEffect(() => {
        if(postResult){
            console.log("Post Result",postResult)
            setMode('Listing')
            navigate(`/${tenant}/orders/Listing`)
        }
    },[postResult])
    return (
        <div className='h-full flex flex-col'>
            <CardHeaderWithActions title={`انشاء امر تشغيل`}>
                {postLoading ? <MwSpinnerButton/> : <MwButton onClick={onSubmitHandler} type='saveBtn'>حفظ</MwButton>}
            </CardHeaderWithActions>
            <div className='flex flex-col'>
                <div className='flex gap-2'>
                    <div className='flex gap-2 flex-1'>
                        <div className='w-28'>
                            <MwInputText
                                label ='رقم الطلب'
                                value={formState.orderNo}
                                onChange={(e) => setFormState((state)=> ({...state,orderNo:e.target.value}) )}
                                invalid = {!!errors?.orderNo}
                                invalidMsg = {errors?.orderNo}
                                disabled = {true}
                                />
                        </div>
                        <div className='flex-1'>
                            <MwInputText
                                label ='وصف مختصر لامر التشغيل'
                                value={formState.orderName}
                                onChange={(e) => setFormState((state)=> ({...state,orderName:e.target.value}) )}
                                invalid = {!!errors?.orderName}
                                invalidMsg = {errors?.orderName}
                                />
                        </div>
                    </div>
                    <div className='flex-1 flex gap-2' >
                        <div className="flex-1 flex  flex-col z-20">
                            <MwSelector 
                                label={'العميل'}
                                initalValue={`اختار العميل...`}
                                _data={customers} 
                                dataType='customer'
                                withAddNew={false}
                                selectedItem = {customerSelected}
                                setSelectedItem = {setCustomerSelected}
                                />
                        </div>
                        <div className="flex-1 flex flex-col z-20">
                            <MwSelector
                                initalValue={`اختار المندوب...`}
                                label={'المندوب'}
                                _data={salesmans} 
                                dataType='salesman'
                                withAddNew={false}
                                selectedItem = {salesmanSelected}
                                setSelectedItem = {setSalesmanSelected}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex flex-1 gap-2 my-3'>
                    <div className=' flex gap-2'>
                        <div className='w-28'>
                            <MwInputText
                                label ='الكمية'
                                value={formState.qty}
                                onChange={(e) => setFormState((state)=> ({...state,qty:e.target.value}) )}
                                invalid = {!!errors?.qty}
                                invalidMsg = {errors?.qty}
                                />
                        </div>
                        {/* <div className='flex-1'>
                            <MwInputText
                                disabled = {true}
                                label ='رقم العرض'
                                value={formState.quoteNo}
                                onChange={(e) => setFormState((state)=> ({...state,quoteNo:e.target.value}) )}
                                invalid = {!!errors?.quoteNo}
                                invalidMsg = {errors?.quoteNo}
                                />
                        </div>
                        <div className='flex-1'>
                            <MwInputText
                                disabled = {true}
                                label ='رقم الفاتورة'
                                value={formState.invoiceNo}
                                onChange={(e) => setFormState((state)=> ({...state,invoiceNo:e.target.value}) )}
                                invalid = {!!errors?.invoiceNo}
                                invalidMsg = {errors?.invoiceNo}
                                />
                        </div> */}
                    </div>
                    <div className='flex-1 flex gap-2'>
                        <div className='flex-1'>
                            <MwInputText
                                label ='تاريخ التسليم'
                                inputType="date"
                                value={formState.deliveryDate}
                                onChange={(e) => setFormState((state)=> ({...state,deliveryDate:e.target.value}) )}
                                invalid = {!!errors?.deliveryDate}
                                invalidMsg = {errors?.deliveryDate}
                                />
                        </div>
                        <div className="flex-1 flex  flex-col ">
                            <MwSelector 
                                label={'المرحلة'}
                                initalValue={`اختار المرحلة...`}
                                _data={stages} 
                                dataType='labelValue'
                                withAddNew={false}
                                selectedItem={formState.stage}
                                setSelectedItem={(newValue) => setFormState((state)=> ({...state,stage:newValue})) }
                                />
                        </div>
                        <div className="flex-1 flex  flex-col ">
                            <MwSelector 
                                label={'حالة امر التشغيل'}
                                initalValue={`اختار الحالة...`}
                                _data={status} 
                                dataType='labelValue'
                                withAddNew={false}
                                selectedItem={formState.status}
                                setSelectedItem={(newValue) => setFormState((state)=> ({...state,status:newValue}))}
                                />
                        </div>
                    </div>
                </div>
                <OrderFormPrinting
                    printingState = {printingState}
                    setPrintingState = {setPrintingState}
                    errors = {errors}
                    />
                <div className='flex flex-col gap-2 '>
                    <MwTextArea
                        label ='الوصف التفصيلي'
                        value={formState.notes}
                        onChange={(e) => setFormState((state)=> ({...state,notes:e.target.value}) )}
                        invalid = {!!errors?.notes}
                        invalidMsg = {errors?.notes}
                        rows={18}
                        />
                </div>
                <div className='py-3'>
                    <MwInputFile
                        label={'الملفات المرفقة'}
                        loading={loadingUploadAttachments} 
                        onChangeHandler={handleAttachmentsUpload}
                    />
                    <div className='flex gap-5 px-3'>
                        {
                            formState?.attachments?.map((attach,i) => <div key={i} >{attach.name}</div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderForm