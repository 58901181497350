import React from 'react'
import { MwInputText, MwSelector, TitleGroup } from '../../../components/ui'

const OrderFormPrinting = ({printingState,setPrintingState,errors}) => {
    const _printingType = [
        {
            label:'وجة واحد',
            value:'oneFace'
        },{
            label:'وجهين',
            value:'twoFaces'
        }
    ];
    const _solofanType = [
        {
            label:'مطفي',
            value:'dark'
        },
        {
            label:'مخمل',
            value:'gray'
        },
        {
            label:'لامع',
            value:'light'
        }
    ];
    const _finishType = [
        {
            label:'بشر',
            value:'1'
        },
        {
            label:'سلك',
            value:'2'
        },
        {
            label:'دبوس',
            value:'3'
        }
    ];
    const _crefType = [
        {
            label:'1 زاوية',
            value:'1'
        },
        {
            label:'2 زاوية',
            value:'2'
        },
        {
            label:'3 زاوية',
            value:'3'
        },
        {
            label:'4 زاوية',
            value:'4'
        }
    ];
    const _cutType = [
        {
            label:'عادي',
            value:'1'
        },
        {
            label:'داي كات',
            value:'2'
        }
    ];
    const _sharnkType = [
        {
            label:'كل كتاب علي حدة',
            value:'1'
        },
        {
            label:'كل مجموعة علي حدة',
            value:'2'
        },
        {
            label:'لا يوجد',
            value:'3'
        }
    ];
    const _paperSizeType = [
        {
            label:'الداخلي',
            value:'1'
        },
        {
            label:'الخارجي',
            value:'2'
        },
        {
            label:'اخري',
            value:'3'
        }
    ];
    const _employee_exec = [
        {
            label:'محمود حافظ ',
            value:'10000001'
        },
        {
            label:'عمرو الهمشري',
            value:'10000002'
        },
        {
            label:' محمد رامس',
            value:'10000003'
        },
        {
            label:'اسماعيل',
            value:'10000004'
        },
        {
            label:'احمد السعودى',
            value:'10000005'
        },
        {
            label:'رياز ابو بكر',
            value:'10000006'
        }
    ];
    return (
        <div className=' rounded-lg bg-slate-100 px-3 pb-3 my-5'>
            <TitleGroup>متغيرات المطبعة</TitleGroup>
            <div className='flex gap-2'>
                <div className='w-96'>
                    {/* <MwInputText
                        label ='اسم المصمم'
                        value={printingState.desginerName}
                        onChange={(e) => setPrintingState((state)=> ({...state,desginerName:e.target.value}) )}
                        invalid = {!!errors?.desginerName}
                        invalidMsg = {errors?.desginerName}
                        /> */}
                        <MwSelector
                            label={'المصمم'}
                            initalValue='اختار المصمم... '
                            _data={_employee_exec}
                            dataType={'labelValue'}
                            withAddNew={false}
                            withSearch={false}
                            selectedItem={printingState.desginerName}
                            setSelectedItem={(newValue) => setPrintingState((state)=> ({...state,desginerName:newValue}))}
                            />
                </div>
                <div className='flex-1 flex gap-2'>
                    <div className='w-28'>
                        <MwInputText
                            label ='وزن الورق'
                            value={printingState.paperWeight}
                            onChange={(e) => setPrintingState((state)=> ({...state,paperWeight:e.target.value}) )}
                            invalid = {!!errors?.paperWeight}
                            invalidMsg = {errors?.paperWeight}
                            />
                    </div>
                    <div className="flex-1">
                        <MwSelector
                            label={'نوع وزن الورق'}
                            initalValue='اختار نوع وزن الورق... '
                            _data={_paperSizeType}
                            dataType={'labelValue'}
                            withAddNew={false}
                            withSearch={false}
                            selectedItem={printingState.paperSizeType}
                            setSelectedItem={(newValue) => setPrintingState((state)=> ({...state,paperSizeType:newValue}))}
                            />
                    </div>
                    <div className='flex-1'>
                        <MwInputText
                            label ='نوع الورق'
                            value={printingState.paperType}
                            onChange={(e) => setPrintingState((state)=> ({...state,paperType:e.target.value}) )}
                            invalid = {!!errors?.paperType}
                            invalidMsg = {errors?.paperType}
                            />
                    </div>
                    <div className="flex-1">
                        <MwSelector
                            label={'نوع السلوفان'}
                            initalValue='اختار نوع السلوفان... '
                            _data={_solofanType}
                            dataType={'labelValue'}
                            withAddNew={false}
                            withSearch={false}
                            selectedItem={printingState.solofanType}
                            setSelectedItem={(newValue) => setPrintingState((state)=> ({...state,solofanType:newValue}))}
                            />
                    </div>
                    <div className="flex-1">
                        <MwSelector
                            label={'نوع الطباعة'}
                            initalValue='اختار نوع الطباعة... '
                            _data={_printingType}
                            dataType={'labelValue'}
                            withAddNew={false}
                            withSearch={false}
                            selectedItem={printingState.printingType}
                            setSelectedItem={(newValue) => setPrintingState((state)=> ({...state,printingType:newValue}))}
                            />
                    </div>
                </div>
            </div>
            <div className='flex gap-2'>
                <div className='flex-1'>
                    <MwInputText
                        label ='اليوفي'
                        value={printingState.uvi}
                        onChange={(e) => setPrintingState((state)=> ({...state,uvi:e.target.value}) )}
                        invalid = {!!errors?.uvi}
                        invalidMsg = {errors?.uvi}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label ='البصمة'
                        value={printingState.touch}
                        onChange={(e) => setPrintingState((state)=> ({...state,touch:e.target.value}) )}
                        invalid = {!!errors?.touch}
                        invalidMsg = {errors?.touch}
                        />
                </div>
                <div className='flex-1'>
                    <MwInputText
                        label ='المقاس النهائي'
                        value={printingState.finalSize}
                        onChange={(e) => setPrintingState((state)=> ({...state,finalSize:e.target.value}) )}
                        invalid = {!!errors?.finalSize}
                        invalidMsg = {errors?.finalSize}
                        />
                </div>
                <div className='flex-1'>
                    {/* <MwInputText
                        label =' التغليف'
                        value={printingState.orderFinish}
                        onChange={(e) => setPrintingState((state)=> ({...state,orderFinish:e.target.value}) )}
                        invalid = {!!errors?.orderFinish}
                        invalidMsg = {errors?.orderFinish}
                        /> */}

                    <MwSelector
                        label={'نوع التغليف'}
                        initalValue='اختار نوع التغليف... '
                        _data={_finishType}
                        dataType={'labelValue'}
                        withAddNew={false}
                        withSearch={false}
                        selectedItem={printingState.orderFinish}
                        setSelectedItem={(newValue) => setPrintingState((state)=> ({...state,orderFinish:newValue}))}
                        />
                </div>
                <div className='flex-1'>
                    <MwSelector
                        label={'نوع الكيرف'}
                        initalValue='اختار نوع الكيرف... '
                        _data={_crefType}
                        dataType={'labelValue'}
                        withAddNew={false}
                        withSearch={false}
                        selectedItem={printingState.crefType}
                        setSelectedItem={(newValue) => setPrintingState((state)=> ({...state,crefType:newValue}))}
                        />
                </div>
                <div className='flex-1'>
                    <MwSelector
                        label={'نوع القص'}
                        initalValue='اختار نوع القص... '
                        _data={_cutType}
                        dataType={'labelValue'}
                        withAddNew={false}
                        withSearch={false}
                        selectedItem={printingState.cutType}
                        setSelectedItem={(newValue) => setPrintingState((state)=> ({...state,cutType:newValue}))}
                        />
                </div>
                <div className='flex-1'>
                    <MwSelector
                        label={'نوع الشرنك'}
                        initalValue='اختار نوع الشرنك... '
                        _data={_sharnkType}
                        dataType={'labelValue'}
                        withAddNew={false}
                        withSearch={false}
                        selectedItem={printingState.sharnkType}
                        setSelectedItem={(newValue) => setPrintingState((state)=> ({...state,sharnkType:newValue}))}
                        />
                </div>
            </div>
        </div>
    )
}

export default OrderFormPrinting